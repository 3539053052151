<template>
    <div>
    <div class="curContainer" style="background: #fff;">
        <div v-if="!isEdit" width="100%">
            <el-row type="flex" justify="end"></el-row>
            <el-form inline :model="searchForm" label-width="140px" style="margin-top: 30px;">
                <el-form-item label="创建日期">
                    <el-date-picker
                    v-model="searchForm.dateArray"
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy-M-d"
                    start-placeholder="年/月/日"
                    end-placeholder="年/月/日">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="关键字">
                    <el-input v-model="searchForm.key" autocomplete="off"></el-input>
                </el-form-item>
                <el-button @click="onSearch" type="primary">搜索</el-button>
            </el-form>
            <div v-if="rightArr.canAdd" style="text-align:right;">
                <el-button type="primary" @click="onModelEdit(null)" style="margin-right:10px;">新建模板</el-button>
            </div>
            <common-table  ref="tableObj" :data="modelList" border :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            style="width: 100%;margin-top: 10px;" stripe>
                <el-table-column prop="projectName" align="center" label="模板名称" >
                    <template v-slot="scope">
                        <!-- <div style="color:#1989FA;cursor:pointer;" @click="onModelEdit(scope.row)">{{scope.row.modelName}}</div>                                     -->
                        {{scope.row.modelName}}
                    </template>                    
                </el-table-column>
                <el-table-column prop="name" align="center" label="创建人" ></el-table-column>
                <el-table-column prop="rowTime" align="center" label="创建时间" ></el-table-column>    
                <el-table-column align="center" label="操作" >
                    <template v-slot="scope">
                        <el-tooltip v-if="rightArr.canModify" class="item" effect="dark" content="编辑" placement="bottom">
                            <el-button type="primary" icon="el-icon-edit" @click="onModelEdit(scope.row)"></el-button>   
                        </el-tooltip>
                        <el-tooltip v-if="rightArr.canDelete" class="item" effect="dark" content="删除" placement="bottom">
                            <el-button type="danger" icon="el-icon-delete" @click="onDeleModel(scope.row)"></el-button>   
                        </el-tooltip>
                        <!-- <el-tooltip class="item" effect="dark" content="设置问卷" placement="bottom">
                            <el-button type="primary" icon="el-icon-setting" @click="onSetQuestion(scope.row)"></el-button>    
                        </el-tooltip>                         -->
                    </template>
                </el-table-column> 
            </common-table>
        </div>
        <div v-else style="min-height:400px;">
            <all-questions is-model="true" @savemodel="onSave" :ef="this.editForm"/>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from "vuex"; 
import {getLatestMonthDateArrayByDate} from "@/utils/time";
import CommonTable from "@/components/CommonTable";
// import eachquestion from '@/views/Survey/bkground/component/eachQuestion';
import allQuestions from '@/views/Survey/bkground/component/allQuestions';
import { DeleModel, SaveModel } from "@/api/survey";
import {getToken} from "@/utils/auth";
import { swapItems, getPreItemWithIndex } from "@/utils/tools";
import {UserRoleModuleDetail} from "@/api/user";

export default {
    components: {
        CommonTable,allQuestions
    },
    computed: {...mapGetters(['modelList','questionTypeList','provinceCityList','modelDetail'])},
    data() {
        return {
            searchForm: {
                dateArray: getLatestMonthDateArrayByDate({date:new Date(),delayNum:1, formatValue: 'yyyy-MM-dd'}),
                key: ''
            },
            isEdit: false,
            editForm: {
                modelName: '',
                questions: []
            },
            editModelVisible: false,
            curModel: {},
            typeId: '',
            curQues: {},
            curIndex: -1,
            setRelationVisible: false,
            canUseQues: [],
            relationA: [],
            relationC: [],
            fromPosition: -1,
            rightArr:{},
        };
    },
    async mounted() {
        var params = {userId:getToken(),moduleId:1}
		console.log('2a')
        await UserRoleModuleDetail(params).then((res)=>{
            // console.log('data',res)
            this.rightArr = res.data[0]
        })

        await this.$store.dispatch('survey/GetProvinceCityList',{userId:getToken(), typeId:0 }).then(()=>{  })
        await this.onSearch();  
        // localStorage.removeItem('curModel') 
        if (localStorage.getItem('curModel')) {
            var cm = JSON.parse(localStorage.getItem('curModel'))
            var len = cm.questions.length
            for (let i = cm.questions.length - 1; i >= 0; i--) {
                if (!cm.questions[i]) {
                    cm.questions.splice(i,1)
                }
            }
            // cm.questions.forEach(element => {
                
            // });
            this.editForm = cm // JSON.parse(localStorage.getItem('curModel'))

            // console.log(this.editForm)
        } 
    },
    methods: {
        async onSearch() {
            const {dateArray: [sYM, eYM],key} = this.searchForm;
            var sDate = new Date(sYM).toLocaleDateString();
            var eDate = new Date(eYM).toLocaleDateString();
            await this.$store.dispatch('survey/GetModelList',{userId: getToken(), sDate, eDate, key: key }).then(()=>{ })
            // await this.$store.dispatch('survey/GetQuestionTypeList',{userId: getToken() }).then(()=>{ })
        },
        // 编辑模板
        async onModelEdit(item) {
            if (item == null) {
                this.editForm = {}
                this.editForm.modelId = 0
                this.editForm.modelName = ''
                this.editForm.questions = []
            } else {
                await this.$store.dispatch('survey/GetModelDetail',{userId: getToken(), modelId:item.modelId }).then(()=>{ 
                    console.log('this.modelDetail',this.modelDetail)
                    this.editForm = this.modelDetail
                })
            }            
            this.isEdit = true
        },

        async onDeleModel(item){      
            this.$confirm('确认删除['+item.modelName+'], 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                var obj = {modelId:item.modelId,userId:getToken()}      
                await DeleModel(obj).then((res)=>{
                    console.log('res',res)
                    this.onSearch()                
                })
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });          
            });
        },

        async onSave(obj){
            // console.log('obj', obj)
            // return;
            if (obj == null){
                this.isEdit = false 
            } else {
                obj.isModel = true
                obj.userId = getToken()
                // console.log(JSON.stringify(this.editForm))
                await SaveModel(obj).then((res)=>{
                    this.onSearch()
                    localStorage.removeItem('curModel') 
                    this.isEdit = false 
                })
            }
            
        }
        
    }
}
</script>
 
<style  lang="scss">
.el-dropdown-menu{
    display:none
}
.dropright:hover > .dropdown-menu {
    display:block;
}
// .el-dropdown-link {
//     cursor: pointer;
//     color: #409EFF;
//   }
//   .el-icon-arrow-down {
//     font-size: 12px;
//   }
.submenu>>>.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    padding: 0px;
}
.my-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin-left:20px;
}
.my-table td {
    border: 1px solid black;
}
table tr:nth-child(2n) {
    background: #fff;
}     
table tr:nth-child(2n+1) {
    background: #edf6ff;
}
.relaTable:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable:nth-child(2n) {
    background: #edf6ff;
    padding:5px;
}
.relaTable2:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable2:nth-child(2n) {
    background: #e4e5e4;
    padding:5px;
}
</style>
